// import Home from "./mintpage.js"
// import Counter from "./Counter";
import Mint from './mintpage.js'
// import MintGold from './mintpagesgold.js'
// import MintDiamond from './mintpagediamond.js'

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import history from './history';

function App() {
  return (

    <BrowserRouter history={history}>
      <Switch>
        <Route exact path="/" component={Mint} />
        {/* <Route exact path="/Mint" component={Mint} /> */}
        {/* <Route exact path="/MintGold" component={MintGold} />
        <Route exact path="/MintDiamond" component={MintDiamond} /> */}

      </Switch>
    </BrowserRouter>


    // return (
    //   <Home />
  );
}

export default App;
