import logo from "./images/MS-500.png";
import history from './history';
import "./App.css";
function Navbar() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }} class="container-fluid">
      <h3 className="logoheading">META</h3>
      <a href="https://www.metasensys.io/" class="navbar-brand">
        <img src={logo} className="imaelogo" alt="CoolBrand" />
      </a>
      <h3 className="logoheading" >SENSYS</h3>

    </div>



  );
}

export default Navbar;
