

// import mintbtn from "./mintbtn.js"
import Navbar from "./mintpagenavbar.js";


import React, { Component } from "react";
// import "react-slideshow-image/dist/styles.css";
import Mintbtn from "./mintbuttonmtn (1).js";



import "./App.css"
import { Zoom } from "react-reveal";

function Home() {

  return (
    <div className="mainclass  ">
      <div id="main" className="container-fluid ">
        <Navbar />

        <hr className="line"></hr>

        <div className=" banner2Container">
          <div className="banner234 ">
            <br></br>
            <br></br>
            <br></br>
            {/* <Counter /> */}
            <Mintbtn />
            {/* <h3 style={{ fontSize: "40px", textAlign: "center", fontWeight: "bolder", color: "Golden", margin: "0px 0px 0px 0px" }} className="">
              <br></br>
              DIAMOND PACKAGE HAS NOT BEEN LAUNCHED YET                </h3> */}
            {/* <h3>THIS PACKAGE HAS NOT BEEN LAUNCHED YET</h3> */}
          </div>


        </div>
      </div>
      <div className="container-fluid paddingremovetop  abvgt ">


      </div>





    </div>

  );
}

export default Home;
